import React from 'react';
import irpfComunidades from '../datos/irpfComunidades.json';
import minimosPersonales from '../datos/minimosPersonales.json';
import gastos from '../datos/gastos.json';
import ssDatos from '../datos/seguridadSocial.json';

const FAMILIA_NUMEROSA_HIJOS_MIN = 3;

type DatosFormulario = {
  brutoAnual: number;
  año: string;
  comunidad: string;
  rentaConjunta: boolean;
  minimosDatos: MinimosDatos;
};

type MinimosDatos = {
  contribuyente: {
    edad: number;
  };
  descendientes: {
    menores25: number;
    menores3: number;
    fallecimiento: number;
  };
  ascendientes: {
    mayores65: number;
    mayores75: number;
    discapacitados: number;
    fallecimiento: number;
  };
  discapacitadosEspeciales: {
    '>33': number;
    '>65': number;
    necesitaAyudaTerceros: number;
  };
};

type MinimoDatosJson = {
  reduccionesBaseImponible: {
    familiaNumerosa?: {
      '=3': number;
      extra: number;
    };
  };
  contribuyente: {
    general: number;
    extra: {
      '>': {
        '65': number;
        '75': number;
      };
    };
  };
  descendientes: {
    general: {
      '=': {
        '1': number;
        '2': number;
        '3': number;
      };
      '>=': {
        '4': number;
      };
    };
    extra: {
      '<': {
        '3': number;
      };
    };
    fallecimiento: number;
  };
  ascendientes: {
    general: {
      '>': {
        '65': number;
      };
    };
    extra: {
      '>': {
        '75': number;
      };
    };
    fallecimiento: number;
  };
  discapacidad: {
    general: {
      '>': {
        '33': number;
        '65': number;
      };
    };
    ayudaTerceros: {
      extra: {
        '>': {
          '65': number;
        };
      };
    };
  };
};

interface MinimoDatosJsonEstructura {
  '2023': {
    Andalucía: MinimoDatosJson;
    Aragón: MinimoDatosJson;
    Asturias: MinimoDatosJson;
    Baleares: MinimoDatosJson;
    Canarias: MinimoDatosJson;
    Cantabria: MinimoDatosJson;
    'Castilla y León': MinimoDatosJson;
    Estado: MinimoDatosJson;
  };
}

type ComunidadAutonoma =
  | 'Andalucía'
  | 'Aragón'
  | 'Asturias'
  | 'Baleares'
  | 'Canarias'
  | 'Cantabria'
  | 'Castilla y León'
  | 'Castilla-La Mancha'
  | 'Cataluña'
  | 'Extremadura'
  | 'Galicia'
  | 'Madrid'
  | 'Murcia'
  | 'Navarra'
  | 'País Vasco'
  | 'La Rioja'
  | 'Valencia';
type Estado = 'Estado';
type ComunidadesConjunto = ComunidadAutonoma | Estado;

const calcularDeducciones = (
  minimosDatosForm: MinimosDatos,
  año,
  comunidad,
  rentaConjunta: boolean,
  restoBaseLiquidable?: number
) => {
  let minimoBaseLiquidable = 0;
  // Comprobar que el año y la comunidad existen en el JSON y coger el valor
  const añoExiste = Object.keys(minimosPersonales).find(
    (key) => key === String(año)
  );
  let comunidadExiste = Object.keys(minimosPersonales[año]).find(
    (item) => item === comunidad
  );
  // Cuando no hay mínimos para una comunidad, utilizar los del Estado
  // Esto es así porque hay comunidades que no tienen mínimos y utilizan
  // los del Estado.
  if (!comunidadExiste) {
    comunidad = 'Estado';
    comunidadExiste = Object.keys(minimosPersonales[año]).find(
      (item) => item === comunidad
    );
  }
  if (!añoExiste || !comunidadExiste) {
    return minimoBaseLiquidable;
  }
  const minimos: MinimoDatosJson = minimosPersonales[año][comunidad];
  if (minimos) {
    const { contribuyente, descendientes, ascendientes, discapacidad } =
      minimos;
    const { general, extra } = contribuyente;
    const {
      general: descendientesGeneral,
      extra: descendientesExtras,
      fallecimiento: descendientesFallecimiento
    } = descendientes;
    const {
      general: ascendientesGeneral,
      extra: ascendientesExtras,
      fallecimiento: fallecimientoAscendientes
    } = ascendientes;
    const { general: discapacidadGeneral, ayudaTerceros } = discapacidad;
    // El general siempre se suma a todo el mundo
    minimoBaseLiquidable += general;
    // Recorrer operandos
    for (const operando in extra) {
      if (operando === '>') {
        // Recorrer todas las edades
        for (const edad in extra[operando]) {
          const edadNum = Number(edad);
          // Pasamos por todas las edades y vamos sumando si cumple
          // la condición de cada rango de edad
          if (minimosDatosForm.contribuyente.edad > edadNum) {
            minimoBaseLiquidable += extra[operando][edad];
          }
        }
      }
      // TODO Añadir más operadores (Solo es necesario '>' por ahora)
    }
    // *** Apartado Descendientes ****
    // Recorrer todos los descendientes en '=', sumar cada posición
    // si tiene mayor o igual número de hijos
    for (const operando in descendientesGeneral['=']) {
      if (minimosDatosForm.descendientes.menores25 >= Number(operando)) {
        // Dividir entre 2 si la renta no es conjunta
        minimoBaseLiquidable += rentaConjunta
          ? descendientesGeneral['='][operando]
          : descendientesGeneral['='][operando] / 2;
      }
    }
    if (minimosDatosForm.descendientes.menores25 >= 4) {
      // Dividir entre 2 si la renta no es conjunta
      minimoBaseLiquidable += rentaConjunta
        ? descendientesGeneral['>=']['4']
        : descendientesGeneral['>=']['4'] / 2;
    }

    if (minimosDatosForm.descendientes.menores3 > 0) {
      // Dividir entre 2 si la renta no es conjunta
      minimoBaseLiquidable += rentaConjunta
        ? descendientesExtras['<']['3']
        : descendientesExtras['<']['3'] / 2;
    }
    if (minimosDatosForm.descendientes.fallecimiento > 0) {
      // Dividir entre 2 si la renta no es conjunta
      minimoBaseLiquidable += rentaConjunta
        ? descendientesFallecimiento
        : descendientesFallecimiento / 2;
    }
    // *** Apartado Ascendientes ***
    if (minimosDatosForm.ascendientes.mayores65 > 0) {
      minimoBaseLiquidable +=
        ascendientesGeneral['>']['65'] *
        minimosDatosForm.ascendientes.mayores65;
    }
    if (minimosDatosForm.ascendientes.mayores75 > 0) {
      // Se suma al anterior de mayores de 65
      minimoBaseLiquidable +=
        ascendientesExtras['>']['75'] * minimosDatosForm.ascendientes.mayores75;
    }
    if (minimosDatosForm.ascendientes.fallecimiento > 0) {
      minimoBaseLiquidable +=
        fallecimientoAscendientes * minimosDatosForm.ascendientes.fallecimiento;
    }
    // *** Discapacidad del contribuyente ***
    if (minimosDatosForm.discapacitadosEspeciales['>33'] > 0) {
      minimoBaseLiquidable += discapacidadGeneral['>']['33'];
    }
    if (minimosDatosForm.discapacitadosEspeciales['>65'] > 0) {
      minimoBaseLiquidable += discapacidadGeneral['>']['65'];
    }
    if (minimosDatosForm.discapacitadosEspeciales.necesitaAyudaTerceros > 0) {
      minimoBaseLiquidable += ayudaTerceros.extra['>']['65'];
    }
    // TODO: Como mejora se podría introducir la discapacidad
    // tanto para los descendientes como para los ascendientes

    // Comprobar que no pase el tope del rango (restoBaseLiquidable)
    // si lo hace, entonces tomar el valor del restoBaseLiquidable
    //if (minimoBaseLiquidable > restoBaseLiquidable) {
    // minimoBaseLiquidable = restoBaseLiquidable;
    //}
  }
  return minimoBaseLiquidable;
};

const calcularSeguridadSocialMensualYBase = (brutoAnual: number, año) => {
  const seguridadSocialAñoExiste = Object.keys(ssDatos).find(
    (key) => key === String(año)
  );
  let ssMensual = 0;
  let salarioMaximoParaCalculo = 0;
  if (seguridadSocialAñoExiste) {
    const seguridadSocialDatos = ssDatos[año];
    const salarioBrutoMensual = brutoAnual / 12;
    salarioMaximoParaCalculo =
      salarioBrutoMensual > seguridadSocialDatos.baseMaxima
        ? seguridadSocialDatos.baseMaxima
        : salarioBrutoMensual;
    const ssSumaPorcentajes =
      seguridadSocialDatos.contigenciasComunes +
      seguridadSocialDatos.formacionProfesional +
      seguridadSocialDatos.desempleo +
      seguridadSocialDatos.MEI;
    ssMensual = salarioMaximoParaCalculo * (ssSumaPorcentajes / 100);
  }

  return {
    ssMensual,
    baseCotizacionSS: salarioMaximoParaCalculo
  };
};

const calcularReduccionesBaseImponible = (
  minimosDatos: MinimosDatos,
  numHijos: number,
  comunidad: string,
  año: string,
  rentaConjunta: boolean
) => {
  let reduccionBaseImponible = 0;
  // Comprobar que el año y la comunidad existen en el JSON y coger el valor
  const añoExiste = Object.keys(minimosPersonales).find(
    (key) => key === String(año)
  );
  let comunidadExiste = Object.keys(minimosPersonales[año]).find(
    (item) => item === comunidad
  );
  if (!añoExiste || !comunidadExiste) {
    return reduccionBaseImponible;
  }
  const minimos: MinimoDatosJson = minimosPersonales[año][comunidad];
  if (minimos) {
    const { reduccionesBaseImponible } = minimos;
    const { familiaNumerosa } = reduccionesBaseImponible;
    // *** Reducciones por familia numerosa ***
    if (numHijos >= FAMILIA_NUMEROSA_HIJOS_MIN && familiaNumerosa) {
      let reduccionBaseImponibleHijos = familiaNumerosa['=3'];
      // Por cada hijo que supere el mínimo, se suma el extra
      if (numHijos > FAMILIA_NUMEROSA_HIJOS_MIN) {
        reduccionBaseImponibleHijos +=
          familiaNumerosa.extra * (numHijos - FAMILIA_NUMEROSA_HIJOS_MIN);
      }
      // Dividir entre 2 si la renta no es conjunta
      if (!rentaConjunta) {
        reduccionBaseImponibleHijos /= 2;
      }
      reduccionBaseImponible += reduccionBaseImponibleHijos;
    }
    // *** Reducciones por discapacidad ***
    // Se introducido también la discapacidad en el fichero de los minimos
    // personales, por si quiero poner cifras diferentes.
    // Pero por ahora, todas las comunidades tomarán los mismos valores
    // que son los del estado.
    const discapacitados = minimosDatos.discapacitadosEspeciales;
    if (discapacitados['>33']) {
      reduccionBaseImponible += gastos.discapacidad['33'];
    }
    // TODO: Los cálculos finales son correctos, pero comprobar por qué
    // hay una diferencia de unos 250 euros en la base imponible con una
    // de las calculadoras de internet
    if (discapacitados['>65']) {
      reduccionBaseImponible += gastos.discapacidad['65'];
    }
    if (discapacitados.necesitaAyudaTerceros) {
      reduccionBaseImponible += gastos.discapacidad.ayudaTerceros;
    }
  }
  return reduccionBaseImponible;
};

// Calcular base imponible o base liquidable general
const calcularBaseImponible = (
  brutoAnual: number,
  minimosDatos: MinimosDatos,
  comunidad: string,
  año: string,
  aplicarReduccion: boolean = false,
  rentaConjunta: boolean = false
) => {
  const seguridadSocialMensual = calcularSeguridadSocialMensualYBase(
    brutoAnual,
    '2024'
  );
  const numHijos =
    minimosDatos.descendientes.menores25 + minimosDatos.descendientes.menores3;
  const reduccionesBaseImponible = calcularReduccionesBaseImponible(
    minimosDatos,
    numHijos,
    comunidad,
    año,
    rentaConjunta
  );
  const baseImponible =
    brutoAnual -
    (aplicarReduccion ? reduccionesBaseImponible : 0) -
    seguridadSocialMensual.ssMensual * 12 -
    gastos.OtrosGastosDeducibles;
  return baseImponible;
};

// Esta función es clave para poder calcular las cuotas
// según los tramos de cada comunidad y del Estado.
// Sirve hacer las siguientes cuotas:
// Calcular cuota 1 y cuota 2 para las rentas del trabajo
// Calcular cuota 3 y cuota 4 para las deducciones
const calcularCuotaTramos = (
  brutoAnualODeducciones: number,
  año: string,
  comunidad: string,
  esDeduccion: boolean,
  minimosDatos: MinimosDatos,
  rentaConjunta: boolean
) => {
  // Para las deducciones, tomamos el bruto anual, que en realidad no es el bruto anual,
  // si no las deducciones totales que se van a aplicar.
  // Si no estamos calculando los impuestos a pagar por las deducciones,
  // entonces calculamos la base imponible pasando el bruto anual.
  const baseImponibleOTotal = esDeduccion
    ? brutoAnualODeducciones
    : calcularBaseImponible(
        brutoAnualODeducciones,
        minimosDatos,
        comunidad,
        año,
        true,
        rentaConjunta
      );
  let cuota = 0;
  // Comprobar que el año y la comunidad existen en el JSON y coger el valor
  const irpfAñoExiste = Object.keys(irpfComunidades).find(
    (key) => key === String(año)
  );
  const irpfComunidadExiste = Object.keys(irpfComunidades[año]).find(
    (item) => item === comunidad
  );

  let tramos: any[] = [];
  if (irpfAñoExiste && irpfComunidadExiste) {
    const irpf = irpfComunidades[año][comunidad];
    if (irpf) {
      cuota = irpf.reduce((acumulador, tramo) => {
        const { baseLiquidable, restoBaseLiquidable, tipoPorcentaje } = tramo;
        let restoBaseLiquidableActualizada = restoBaseLiquidable;

        // Actualizar baseLiquidable solo para el primer tramo
        if (acumulador === 0) {
          /*const primerTramoBaseLiquidable = calcularDeducciones(
            minimosDatos,
            año,
            comunidad,
            restoBaseLiquidable
          );
          restoBaseLiquidableActualizada =
            restoBaseLiquidable - primerTramoBaseLiquidable;
            */
          restoBaseLiquidableActualizada = restoBaseLiquidable;
        }
        const maximo = Number(baseLiquidable) + Number(restoBaseLiquidable);
        // if (Number(baseImponible) > Number(maximo)) {
        if (
          Number(baseImponibleOTotal) > Number(maximo) &&
          Number(restoBaseLiquidable) > 0
        ) {
          const impuesto =
            (Number(restoBaseLiquidableActualizada) * tipoPorcentaje) / 100;
          const impuestoSinMinimos =
            (Number(restoBaseLiquidable) * tipoPorcentaje) / 100;
          tramos.push({
            baseLiquidable,
            restoBaseLiquidableActualizada: restoBaseLiquidableActualizada || 0,
            restoBaseLiquidable: restoBaseLiquidable,
            tipoPorcentaje,
            impuestoSinMinimos,
            impuesto
          });
          return acumulador + impuesto;
        } else {
          if (Number(baseImponibleOTotal) < Number(baseLiquidable)) {
            return acumulador;
          }
          const impuesto =
            ((Number(baseImponibleOTotal) - Number(baseLiquidable)) *
              tipoPorcentaje) /
            100;
          tramos.push({
            baseLiquidable,
            restoBaseLiquidable: restoBaseLiquidableActualizada || 0,
            tipoPorcentaje,
            impuestoSinMinimos: impuesto,
            impuesto
          });
          return acumulador + impuesto;
        }
      }, 0);
    }
  }

  if (cuota < 0) {
    // Si la cuota sale negativa, es porque las bonificaciones han hecho
    // que el impuesto a pagar sea negativo, pero la Agencia Tributaria
    // no nos va a pagar, así que ponemos la cuota a 0
    cuota = 0;
  }

  return {
    cuota, // Cuota1 y cuota2
    tramos,
    baseImponible: calcularBaseImponible(
      brutoAnualODeducciones,
      minimosDatos,
      comunidad,
      año,
      false
    ), // Base imponible sin reducciones
    baseImponibleConReducciones: baseImponibleOTotal
  };
};

const calcularIRPF = ({
  brutoAnual,
  año,
  comunidad,
  rentaConjunta,
  minimosDatos
}: DatosFormulario) => {
  // Calcular todas las cuotas integras de todos los tramos,
  // teniendo como tope el bruto anual.
  // La cuota de la comunidad es solo la mitad, el otro 50% viene
  // de la cuota estatal (Comunidad: Estado).
  // Cuota 2 (Autonómica)
  const cuotaComunidad = calcularCuotaTramos(
    brutoAnual,
    año,
    comunidad,
    false,
    minimosDatos,
    rentaConjunta
  );
  const deduccionesComunidadAntesImpuestos = calcularDeducciones(
    minimosDatos,
    año,
    comunidad,
    rentaConjunta
  );
  // Cuota 4
  const cuotaDeduccionesComunidad = calcularCuotaTramos(
    deduccionesComunidadAntesImpuestos,
    año,
    comunidad,
    true,
    minimosDatos,
    rentaConjunta
  );
  // Cuota 1 (Estatal)
  const cuotaEstatal = calcularCuotaTramos(
    brutoAnual,
    año,
    'Estado',
    false,
    minimosDatos,
    rentaConjunta
  );
  const deduccionesEstadoAntesImpuestos = calcularDeducciones(
    minimosDatos,
    año,
    'Estado',
    rentaConjunta
  );
  // Cuota 3
  const cuotaDeduccionesEstado = calcularCuotaTramos(
    deduccionesEstadoAntesImpuestos,
    año,
    'Estado',
    true,
    minimosDatos,
    rentaConjunta
  );
  const cuotaTotal =
    cuotaComunidad.cuota +
    cuotaEstatal.cuota -
    cuotaDeduccionesComunidad.cuota -
    cuotaDeduccionesEstado.cuota;
  const seguridadSocialMensual = calcularSeguridadSocialMensualYBase(
    brutoAnual,
    '2024'
  );
  const sueldoNetoAnual =
    brutoAnual - cuotaTotal - seguridadSocialMensual.ssMensual * 12;
  return {
    cuotaComunidadAntesDeducciones: cuotaComunidad.cuota, // Cuota 2
    cuotaEstatalAntesDeducciones: cuotaEstatal.cuota, // Cuota 1
    cuotaComunidad: cuotaComunidad.cuota - cuotaDeduccionesComunidad.cuota, // Cuota íntegra general autonómica
    cuotaEstatal: cuotaEstatal.cuota - cuotaDeduccionesEstado.cuota, // Cuota íntegra general estatal
    cuotaTotalAntesDeducciones: cuotaComunidad.cuota + cuotaEstatal.cuota,
    cuotaTotal,
    sueldoNetoAnual,
    SueldoNetoMensual: sueldoNetoAnual / 12,
    ssMensual: seguridadSocialMensual.ssMensual,
    baseCotizacionSS: seguridadSocialMensual.baseCotizacionSS,
    tramosComunidad: cuotaComunidad.tramos,
    tramosEstatal: cuotaEstatal.tramos,
    baseImponible: cuotaComunidad.baseImponible,
    baseImponibleConReducciones: cuotaComunidad.baseImponibleConReducciones,
    cuotaDeduccionesComunidad: cuotaDeduccionesComunidad.cuota,
    cuotaDeduccionesEstado: cuotaDeduccionesEstado.cuota,
    deduccionesComunidadAntesImpuestos,
    deduccionesEstadoAntesImpuestos
  };
};

// TODO:
// El tercer hijo aun no funciona bien, se ve que el calculo por tramos no lo hace correctamente.
//  Ejemplo para 3 hijos.
// Calculo parte estatal:
// (5550+2400+2700+4000) = 14650
// El tope del primer tramo es 12450 (9.5%)
// 14650-12450 = 2200 sería para el segundo tramo (12%)
// en total:
// 2200×0.12+12450×0.095
//

const formActualizarBrutoAnual = (setBrutoAnual) => {
  return (
    <div>
      <label>Bruto anual:</label>
      <input
        style={{ backgroundColor: '#ccc' }}
        type="number"
        onChange={(e) => setBrutoAnual(e.target.value)}
      />
    </div>
  );
};

/**
 * TODO: Los 1200euros de famlia numerosa, al 50% cuando no es renta conjunta,
 * se restan de la base imponible, por lo que no se suma a las deducciones.
 * En la calculadora de internet, baja justo 600 euros con el tercer hijo de la base imponible
 * De ahí que no salga en las deducciones.
 * Buscar en hacienda o fuentes alternativas, si esto es así. Porque al restarse
 * a la base imponible, se obtiene muchísimo mejor resultado, que si se suma a las deducciones.
 * Un 45% en vez de un 19% mas o menos para un ejempo de 70.000 euros de sueldo bruto.
 */

const Calculadora = () => {
  // Bruto anual
  const [brutoAnual, setBrutoAnual] = React.useState(0);
  const [año, setAño] = React.useState('2024');
  const [comunidad, setComunidad] = React.useState('Madrid');
  const [edadContribuyente, setEdadContribuyente] = React.useState(0);
  const [descendientesMenores25, setDescendientesMenores25] = React.useState(0);
  const [descendientesMenores3, setDescendientesMenores3] = React.useState(0);
  const [descendientesFallecimiento, setDescendientesFallecimiento] =
    React.useState(0);
  const [ascendientesMayores65, setAscendientesMayores65] = React.useState(0);
  const [ascendientesMayores75, setAscendientesMayores75] = React.useState(0);
  const [ascendientesDiscapacitados, setAscendientesDiscapacitados] =
    React.useState(0);
  const [ascendientesFallecimiento, setAscendientesFallecimiento] =
    React.useState(0);
  const [discapacitadosEspeciales33, setDiscapacitadosEspeciales33] =
    React.useState(0);
  const [discapacitadosEspeciales65, setDiscapacitadosEspeciales65] =
    React.useState(0);
  const [
    discapacitadosEspecialesAyudaTerceros,
    setDiscapacitadosEspecialesAyudaTerceros
  ] = React.useState(0);
  const [rentaConjunta, setRentaConjunta] = React.useState(false);

  const styles = {
    formContainer: {
      backgroundColor: '#fff',
      padding: '24px',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      maxWidth: '448px',
      margin: '0 auto'
    },
    formGroup: {
      marginBottom: '16px'
    },
    formLabel: {
      display: 'block',
      fontWeight: 'bold',
      color: '#4a5568',
      marginBottom: '8px'
    },
    formInput: {
      width: '100%',
      padding: '8px 12px',
      border: '1px solid #cbd5e0',
      borderRadius: '4px',
      fontSize: '16px',
      color: '#4a5568'
    },
    formInputFocus: {
      outline: 'none',
      borderColor: '#4299e1',
      boxShadow: '0 0 0 2px rgba(66, 153, 225, 0.5)'
    },
    tramosContenedor: {
      backgroundColor: '#f7fafc',
      padding: '16px',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      marginTop: '16px'
    },
    tramo: {
      padding: '8px',
      backgroundColor: '#edf2f7',
      borderRadius: '4px',
      marginBottom: '8px'
    }
  };

  // Input para el bruto anual
  /*const actualizarBrutoAnual = () => {
    setBrutoAnual(bruto);
  };*/

  const datosFormulario: DatosFormulario = {
    brutoAnual,
    año,
    comunidad: comunidad,
    rentaConjunta,
    minimosDatos: {
      contribuyente: {
        edad: edadContribuyente
      },
      descendientes: {
        menores25: descendientesMenores25,
        menores3: descendientesMenores3,
        fallecimiento: descendientesFallecimiento
      },
      ascendientes: {
        mayores65: ascendientesMayores65,
        mayores75: ascendientesMayores75,
        discapacitados: ascendientesDiscapacitados,
        fallecimiento: ascendientesFallecimiento
      },
      discapacitadosEspeciales: {
        '>33': discapacitadosEspeciales33,
        '>65': discapacitadosEspeciales65,
        necesitaAyudaTerceros: discapacitadosEspecialesAyudaTerceros
      }
    }
  };

  const costesIRPF = calcularIRPF(datosFormulario);

  //

  return (
    <div>
      <h1>Calculadora de sueldo</h1>
      <div className="formulario" style={styles.formContainer}>
        <div style={styles.formGroup}>
          <label>Año:</label>
          <select onChange={(e) => setAño(e.target.value)}>
            <option value="2024">2024</option>
            <option value="2023">2023</option>
          </select>
        </div>
        <div style={styles.formGroup}>
          <label>Comunidad:</label>
          <select
            value={comunidad}
            onChange={(e) => setComunidad(e.target.value)}
          >
            {Object.keys(irpfComunidades[año]).map((comunidad) => (
              <option value={comunidad}>{comunidad}</option>
            ))}
          </select>
        </div>
        <div style={styles.formGroup}>
          <label>Edad contribuyente:</label>
          <input
            type="number"
            onChange={(e) => setEdadContribuyente(Number(e.target.value))}
            value={edadContribuyente}
          />
        </div>
        <div style={styles.formGroup}>
          <label>Total hijos (Menores de 25):</label>
          <input
            type="number"
            onChange={(e) => setDescendientesMenores25(Number(e.target.value))}
            value={descendientesMenores25}
          />
        </div>
        <div style={styles.formGroup}>
          <label>Hijos menores de 3 años:</label>
          <input
            type="number"
            onChange={(e) => setDescendientesMenores3(Number(e.target.value))}
            value={descendientesMenores3}
          />
        </div>
        <div style={styles.formGroup}>
          <label>Descendientes fallecimiento:</label>
          <input
            type="number"
            onChange={(e) =>
              setDescendientesFallecimiento(Number(e.target.value))
            }
            value={descendientesFallecimiento}
          />
        </div>
        <div style={styles.formGroup}>
          <label>Ascendientes mayores 65:</label>
          <input
            type="number"
            onChange={(e) => setAscendientesMayores65(Number(e.target.value))}
            value={ascendientesMayores65}
          />
        </div>
        <div style={styles.formGroup}>
          <label>Ascendientes mayores 75:</label>
          <input
            type="number"
            onChange={(e) => setAscendientesMayores75(Number(e.target.value))}
            value={ascendientesMayores75}
          />
        </div>
        <div style={styles.formGroup}>
          <label>Ascendientes discapacitados:</label>
          <input
            type="number"
            onChange={(e) =>
              setAscendientesDiscapacitados(Number(e.target.value))
            }
            value={ascendientesDiscapacitados}
          />
        </div>
        <div style={styles.formGroup}>
          <label>Ascendientes fallecimiento:</label>
          <input
            type="number"
            onChange={(e) =>
              setAscendientesFallecimiento(Number(e.target.value))
            }
            value={ascendientesFallecimiento}
          />
        </div>
        <div style={styles.formGroup}>
          <label>Discapacitados mayor 33%:</label>
          <input
            type="number"
            onChange={(e) =>
              setDiscapacitadosEspeciales33(Number(e.target.value))
            }
            value={discapacitadosEspeciales33}
          />
        </div>
        <div style={styles.formGroup}>
          <label>Discapacitados mayor 65%:</label>
          <input
            type="number"
            onChange={(e) =>
              setDiscapacitadosEspeciales65(Number(e.target.value))
            }
            value={discapacitadosEspeciales65}
          />
        </div>
        <div style={styles.formGroup}>
          <label>Discapacitados necesita ayuda terceros:</label>
          <input
            type="number"
            onChange={(e) =>
              setDiscapacitadosEspecialesAyudaTerceros(Number(e.target.value))
            }
            value={discapacitadosEspecialesAyudaTerceros}
          />
        </div>
        <div style={styles.formGroup}>
          <label>Renta conjunta:</label>
          <input
            type="checkbox"
            onChange={(e) => setRentaConjunta(!rentaConjunta)}
            checked={rentaConjunta}
          />
        </div>
      </div>
      {formActualizarBrutoAnual(setBrutoAnual)}
      {(brutoAnual && (
        <div>
          <h2>Sueldo neto anual:</h2>
          <p>{costesIRPF.sueldoNetoAnual.toFixed(2)}</p>
          <h2>
            <b>Sueldo neto mensual:</b>
          </h2>
          <p>
            <b>{costesIRPF.SueldoNetoMensual.toFixed(2)}</b>
          </p>
          <h2>Base cotización Seguridad Social:</h2>
          <p>{costesIRPF.baseCotizacionSS.toFixed(2)}</p>
          <h2>Cuota mensual Seguridad Social:</h2>
          <p>{costesIRPF.ssMensual.toFixed(2)}</p>
          <h2>Cuota anual Seguridad Social:</h2>
          <p>{(costesIRPF.ssMensual * 12).toFixed(2)}</p>
          {comunidad !== 'Estado' && (
            <>
              <h2>
                IRPF estatal (Antes de deducciones:{' '}
                {costesIRPF.cuotaEstatalAntesDeducciones.toFixed(2)}
                ):
              </h2>
              <p>{costesIRPF.cuotaEstatal.toFixed(2)}</p>
              <h2>
                IRPF Comunidad:(Antes de deducciones:{' '}
                {costesIRPF.cuotaComunidadAntesDeducciones.toFixed(2)}
                ):
              </h2>
              <p>{costesIRPF.cuotaComunidad.toFixed(2)}</p>
            </>
          )}
          <h2>
            Total IRPF (Antes de deducciones:{' '}
            {costesIRPF.cuotaTotalAntesDeducciones.toFixed(2)}):
          </h2>
          <p>{costesIRPF.cuotaTotal.toFixed(2)}</p>
          <h2>Deducciones Comunidad (antes impuestos):</h2>
          <p>{costesIRPF.deduccionesComunidadAntesImpuestos.toFixed(2)}</p>
          <h2>Deducciones Estado (antes impuestos):</h2>
          <p>{costesIRPF.deduccionesEstadoAntesImpuestos.toFixed(2)}</p>
          {(comunidad !== 'Estado' && (
            <>
              <h2>Deducciones Comunidad (después impuestos):</h2>
              <p>{costesIRPF.cuotaDeduccionesComunidad.toFixed(2)}</p>
            </>
          )) || <div></div>}
          <h2>Deducciones Estado (después impuestos):</h2>
          <p>
            {(comunidad !== 'Estado' &&
              costesIRPF.cuotaDeduccionesEstado.toFixed(2)) ||
              (costesIRPF.cuotaDeduccionesEstado * 2).toFixed(2)}
          </p>
          <h2>Base imponible (Base liquidable general):</h2>
          <p>{costesIRPF.baseImponible}</p>
          <h2>Base imponible con reducciones:</h2>
          <p>{costesIRPF.baseImponibleConReducciones}</p>
          {comunidad !== 'Estado' && (
            <>
              <h2>Tramos IRPF Comunidad:</h2>
              <ul style={styles.tramosContenedor}>
                {costesIRPF.tramosComunidad.map((tramo) => (
                  <li style={styles.tramo}>
                    <p>Base liquidable: {tramo.baseLiquidable}</p>
                    <p>Resto base liquidable: {tramo.restoBaseLiquidable}</p>
                    <p>
                      Resto base liquidable actualizada:
                      {tramo.restoBaseLiquidableActualizada}
                    </p>
                    <p>Tipo porcentaje: {tramo.tipoPorcentaje}</p>
                    <p>Impuesto sin mínimos: {tramo.impuestoSinMinimos}</p>
                    <p>Impuesto: {tramo.impuesto.toFixed(2)}</p>
                  </li>
                ))}
              </ul>
            </>
          )}
          <h2>Tramos IRPF Estatal:</h2>
          <ul style={styles.tramosContenedor}>
            {costesIRPF.tramosEstatal.map((tramo) => (
              <li style={styles.tramo}>
                <p>Base liquidable: {tramo.baseLiquidable}</p>
                <p>Resto base liquidable: {tramo.restoBaseLiquidable}</p>
                <p>
                  Resto base liquidable actualizada:
                  {tramo.restoBaseLiquidableActualizada}
                </p>
                <p>
                  Tipo porcentaje:{' '}
                  {(comunidad !== 'Estado' && tramo.tipoPorcentaje) ||
                    tramo.tipoPorcentaje * 2}
                </p>
                <p>
                  Impuesto:{' '}
                  {(comunidad !== 'Estado' &&
                    tramo.impuestoSinMinimos.toFixed(2)) ||
                    (tramo.impuestoSinMinimos * 2).toFixed(2)}
                </p>
                <p>
                  Impuesto:{' '}
                  {(comunidad !== 'Estado' && tramo.impuesto.toFixed(2)) ||
                    (tramo.impuesto * 2).toFixed(2)}
                </p>
              </li>
            ))}
          </ul>
        </div>
      )) || <div></div>}
      <div></div>
    </div>
  );
};

export default Calculadora;
