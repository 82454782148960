import { GoogleLogin } from '@react-oauth/google';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootStoreContext } from '../states/RootStore';
import { observer } from 'mobx-react';

export const Login = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const navigate = useNavigate();
  const handleSuccess = (response: any) => {
    const token = response.credential;
    // Save the token in the local storage
    rootStore.setToken(token);
    // localStorage.setItem('token', token);
    // Reload the page
    // window.location.reload();
    // Redirect to the home page
    // TODO: Perhaps we can detect where the user was before login
    navigate('/');
  };
  const handleError = () => {
    // Show an error message to the user using a toast using material ui
    console.error('Impossible to login');
  };
  return (
    <div>
      <h1>Login</h1>
      <GoogleLogin onSuccess={handleSuccess} onError={handleError} />
    </div>
  );
});
