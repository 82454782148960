import React from 'react';

const Main = () => {
  return (
    <main>
      <section>
        <h1>Gestión de gastos familiares</h1>
        <p>
          {' '}
          Esta aplicación ofrece diferentes recursos para la gestión de la
          economía familiar.
        </p>
      </section>
      <section>
        <h2>Calculadoras</h2>
        <p>
          En primer lugar, tienes disponibles una calculadora de sueldo neto
          para trabajadores asalariados, la cual permite la selección de tu
          comunidad autónoma. Utiliza los cálculos reales que realiza la Agencia
          Tributaria, y admite diferentes tipos de desgravaciones. En segundo
          lugar, también tienes a tu disposición una calculadora de hipotecas.
          Podrás calcular la cuota mentual, con tan solo especificar los años de
          amortización, el interés anual y el capital pendiente.
        </p>
        <h2>Gastos personales</h2>
        <p>
          Podrás guadar y controlar todos los gastos diarios que tengas durante
          el mes. Puedes incluir todos los gastos recurrentes y recibir alertas
          cuando se esté acercando la fecha del cargo. Con todos estos datos,
          podrás analizar y calcular los gastos que tienes mensualmente, y
          también el ahorro mensual que generas. También podrás crear proyectos
          o metas de ahorro, y ver la fecha en la que complirás con dicha meta.
        </p>
      </section>
    </main>
  );
};

export default Main;
