import { observer } from 'mobx-react';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootStoreContext } from '../states/RootStore';

export const Logout = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const navigate = useNavigate();

  useEffect(() => {
    // Eliminar el token de localStorage
    rootStore.clearToken();
    // localStorage.removeItem('token');

    // Opcional: Puedes agregar lógica adicional, como limpiar otros datos del estado global (MobX, Redux, etc.).

    // Redirigir al usuario a la página de inicio de sesión
    navigate('/');
  }, [navigate]);

  return <div>Please wait...</div>;
});
