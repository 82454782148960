import React, { useContext } from 'react';
import { RootStoreContext } from '../../core/states/RootStore';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button } from '@mui/material';
import { observer } from 'mobx-react';
import { DailyExpense } from '../states/DailyExpensesStore';
import { months } from '../lib/time';

const DailyExpenses = observer(() => {
  const storeContext = useContext(RootStoreContext);
  const store = storeContext.dailyExpensesStore;
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const currentMonthName = months[currentMonth];

  const [selectedYear, setSelectedYear] = React.useState(currentYear);
  const [selectedMonth, setSelectedMonth] = React.useState(currentMonth);

  const [filteredExpenses, setFilteredExpenses] = React.useState<
    DailyExpense[]
  >([]);

  const filterExpensesByMonthAndYear = (month, year) => {
    setFilteredExpenses(store.filterDailyExpensesMyMonthAndYear(month, year));
  };

  React.useEffect(() => {
    const loadExpenses = async () => {
      await store.loadDailyExpenses();
      setFilteredExpenses(
        store.filterDailyExpensesMyMonthAndYear(selectedMonth, selectedYear)
      );
    };
    loadExpenses();
  }, []);

  const getAvailableYears = () => {
    const years = store.availableYears;
    if (years.length === 0) {
      return [currentYear];
    }
    return years;
  };

  const handleCreateDailyExpense = () => {
    // Add new expense for the selected month and year
    const date = new Date(selectedYear, selectedMonth + 1, 0);
    store.createDailyExpense(date);
  };

  return (
    <div>
      <h1>Gastos diarios</h1>
      {getAvailableYears().map((year) => (
        <Button
          key={year}
          onClick={() => {
            setSelectedYear(year);
            filterExpensesByMonthAndYear(selectedMonth, year);
          }}
        >
          {year}
        </Button>
      ))}
      {months.map((month, index) => (
        <Button
          key={month}
          onClick={() => {
            setSelectedMonth(index);
            filterExpensesByMonthAndYear(index, currentYear);
          }}
        >
          {month}
        </Button>
      ))}
      <Button onClick={() => store.updateDailyExpenses()}>Guardar</Button>
      {store
        .filterDailyExpensesMyMonthAndYear(selectedMonth, selectedYear)
        .map((row) => (
          <div key={row.id as React.Key}>
            <input
              type="text"
              value={row.concept}
              onChange={(e) => (row.concept = e.target.value)}
              placeholder="Concepto"
            />
            <input
              type="number"
              value={row.cost}
              onChange={(e) => (row.cost = parseFloat(e.target.value))}
              placeholder="Cantidad"
            />
            <DatePicker
              selected={row.date}
              onChange={(date: Date | null) => {
                if (date) {
                  row.date = date;
                }
              }}
            />
            <select
              value={row.category.id}
              onChange={(e) => (row.category.id = e.target.value)}
            >
              {store.categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
            <button onClick={() => row.delete()}>Eliminar Fila</button>
          </div>
        ))}
      <button onClick={() => handleCreateDailyExpense()}>Añadir Fila</button>
      <div>
        <button onClick={() => store.updateDailyExpenses()}>Guardar</button>
      </div>
      <div>
        <span> Gasto total:</span>
        <span>
          {filteredExpenses.reduce((total, expense) => total + expense.cost, 0)}
        </span>
      </div>
    </div>
  );
});

export default DailyExpenses;
