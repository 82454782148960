import axios from 'axios';

export const getFullUrl = () => {
  const protocol = window.location.protocol;
  const hostname = window.location.hostname;
  const port = window.location.port;
  const domain = port ? `${hostname}:${port}` : hostname;
  const fullUrl = `${protocol}//${domain}`;
  return fullUrl;
};

export const logoutUser = () => {
  // TODO: Maybe we can use the mobxstore to save the state of the login
  localStorage.removeItem('token');

  // Redirect to login page
  // window.location.href = '/login';
};

export const isUserLoggedIn = async (token) => {
  const fullUrl = getFullUrl();
  let isLoggedIn = false;
  if (token) {
    let response: any = undefined;
    try {
      response = await axios.get(`${fullUrl}/api/common/verifyToken`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response?.data?.verified) {
        isLoggedIn = true;
      }
    } catch (error) {
      isLoggedIn = false;
    }
  } else {
    isLoggedIn = false;
  }
  return isLoggedIn;
};
