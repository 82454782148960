import React from 'react';
import { DailyExpensesStore } from '../../gastosPersonales/states/DailyExpensesStore';
import { RecurringExpensesStore } from '../../gastosPersonales/states/RecurringExpensesStore';
import { ExpensesTransportLayer } from '../../gastosPersonales/states/ExpensesTransportLayer';
import { makeAutoObservable } from 'mobx';

export class RootStore {
  dailyExpensesStore: DailyExpensesStore;
  gastosRecurrentesStore: RecurringExpensesStore;
  token: string = '';
  constructor() {
    makeAutoObservable(this);
    this.dailyExpensesStore = new DailyExpensesStore(
      new ExpensesTransportLayer(this)
    );
    this.gastosRecurrentesStore = new RecurringExpensesStore(
      new ExpensesTransportLayer(this)
    );
  }

  setToken(token: string) {
    this.token = token;
  }

  clearToken() {
    this.token = '';
  }
}

export const rootStore = new RootStore();
export const RootStoreContext = React.createContext(rootStore);
