import axios, { AxiosResponse } from 'axios';
import { getFullUrl } from '../../core/lib/helper';
import { RootStore } from '../../core/states/RootStore';
import { makeAutoObservable } from 'mobx';

export class ExpensesTransportLayer {
  fullUrl;
  store: RootStore | null = null;
  constructor(store: RootStore) {
    this.store = store;
    this.fullUrl = getFullUrl();
  }
  async getDailyExpenses(): Promise<any> {
    let response: AxiosResponse<any> | undefined = undefined;
    try {
      response = await axios.get(`${this.fullUrl}/api/dailyExpenses`, {
        headers: { Authorization: `Bearer ${this.store?.token}` }
      });
    } catch (error) {
      console.error('Error getting daily expenses', error);
    }

    return response?.data || [];
  }

  async getRecurringExpenses() {
    let response: AxiosResponse<any> | undefined = undefined;
    try {
      response = await axios.get(`${this.fullUrl}/api/recurringExpenses`, {
        headers: { Authorization: `Bearer ${this.store?.token}` }
      });
    } catch (error) {
      console.error('Error getting recurring expenses', error);
    }
    return response?.data || [];
  }

  async saveDailyExpenses(expenses) {
    let response: AxiosResponse<any> | undefined = undefined;
    try {
      response = await axios.post(
        `${this.fullUrl}/api/dailyExpenses`,
        expenses,
        {
          headers: { Authorization: `Bearer ${this.store?.token}` }
        }
      );
    } catch (error) {
      console.error('Error saving daily expenses', error);
    }
    return response;
  }

  async saveRecurringExpenses(expenses) {
    let response: AxiosResponse<any> | undefined = undefined;
    try {
      response = await axios.post(
        `${this.fullUrl}/api/recurringExpenses`,
        {
          headers: { Authorization: `Bearer ${this.store?.token}` }
        },
        expenses
      );
    } catch (error) {
      console.error('Error saving recurring expenses', error);
    }
    return response;
  }

  async getCategories() {
    let response: AxiosResponse<any> | undefined = undefined;
    try {
      response = await axios.get(`${this.fullUrl}/api/categories`, {
        headers: { Authorization: `Bearer ${this.store?.token}` }
      });
    } catch (error) {
      console.error('Error getting categories', error);
    }
    return response?.data || [];
  }
}
