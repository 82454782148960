export class RecurringExpensesStore {
  transportLayer;
  isLoading;
  isSaving;

  constructor(transportLayer) {
    this.isLoading = false;
    this.isSaving = false;
    this.transportLayer = transportLayer;
  }

  async getRecurringExpenses() {
    this.isLoading = true;
    const response = await this.transportLayer.getRecurringExpenses();
    this.isLoading = false;
    return response.data;
  }

  async saveRecurringExpenses(gasto) {
    this.isSaving = true;
    const response = await this.transportLayer.saveRecurringExpenses(gasto);
    this.isSaving = false;
    return response.data;
  }
}
