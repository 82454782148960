import {
  AppBar,
  IconButton,
  Link,
  MenuItem,
  Toolbar,
  Typography
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import React, { useContext, useEffect, useState } from 'react';
import { isUserLoggedIn } from '../core/lib/helper';
import { observer } from 'mobx-react';
import { RootStoreContext } from '../core/states/RootStore';
import { set } from 'mobx';

const Menu = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  useEffect(() => {
    const checkUserLoggedIn = async () => {
      const token = rootStore.token;
      const logged = await isUserLoggedIn(token);
      setUserLoggedIn(logged);
    };
    checkUserLoggedIn();
  }, [rootStore.token]);

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton>
        <Typography variant="h6">Finanzas fácil</Typography>

        <MenuItem component={RouterLink} to="/">
          Inicio
        </MenuItem>
        <MenuItem component={RouterLink} to="/calculadora">
          Calculadora de sueldo
        </MenuItem>
        <MenuItem component={RouterLink} to="/calculadora-hipoteca">
          Calculadora de hipoteca
        </MenuItem>
        <MenuItem component={RouterLink} to="/gastos-personales">
          Gastos personales diarios
        </MenuItem>
        {userLoggedIn ? (
          <MenuItem component={RouterLink} to="/logout">
            Salir
          </MenuItem>
        ) : (
          <MenuItem component={RouterLink} to="/login">
            Iniciar sesión
          </MenuItem>
        )}
      </Toolbar>
    </AppBar>
  );
});

export default Menu;
