import React from 'react';
import { Outlet } from 'react-router-dom';
import PrivateMenu from '../../menu/PrivateMenu';

const Layout = () => {
  return (
    <div>
      <PrivateMenu />
      <h1>Header</h1>
      <div>
        <Outlet />
      </div>
      <h1>Footer</h1>
    </div>
  );
};

export default Layout;
