import React, { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import './index.css';
import Layout from './modules/core/components/Layout';
import Ejemplo from './modules/core/components/Ejemplo';
import Calculadora from './modules/calculadoraSueldo/components/CalculadoraSueldo';
import CalculadoraHipoteca from './modules/calculadoraHipoteca/components/CalculadoraHipoteca';
import Main from './modules/main/components/Main';
import { Login } from './modules/core/components/Login';
import { Logout } from './modules/core/components/Logout';
import { PrivateRoute } from './modules/core/components/PrivateRoute';
import { GoogleOAuthProvider } from '@react-oauth/google';
import axios from 'axios';
import { getFullUrl } from './modules/core/lib/helper';
import DailyExpenses from './modules/gastosPersonales/components/DailyExpenses';
import { observer } from 'mobx-react';
import { RootStoreContext } from './modules/core/states/RootStore';

const App = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const [googleClientId, setGoogleClientId] = React.useState('');
  const fullUrl = getFullUrl();
  const token = rootStore.token;

  // If we can't get the token from local storage, redirect to login page
  React.useEffect(() => {
    if (window.location.pathname === '/login') {
      return;
    }
    if (!token) {
      window.location.href = '/login';
    }
  });

  React.useEffect(() => {
    try {
      axios.get(`${fullUrl}/api/common/google`).then((response) => {
        setGoogleClientId(response.data.clientId);
      });
    } catch (error) {
      console.error('Error getting google client id');
      rootStore.setToken('');
    }
  }, [fullUrl]);

  if (!googleClientId) {
    return <div>Loading...</div>;
  }

  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Main />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/calculadora" element={<Calculadora />} />
          <Route
            path="/calculadora-hipoteca"
            element={<CalculadoraHipoteca />}
          />

          <Route path="/protected" element={<Ejemplo />} />
          <Route
            path="/gastos-personales"
            element={
              <PrivateRoute>
                <DailyExpenses />
              </PrivateRoute>
            }
          />
        </Route>
      </Routes>
    </GoogleOAuthProvider>
  );
});

export default App;
