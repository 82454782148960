import React, { useState } from 'react';

const CalculadoraHipoteca = () => {
  const [capitalPendiente, setCapitalPendiente] = useState(0);
  const [plazoAmortizacion, setPlazoAmortizacion] = useState(0); // En años
  const [interes, setInteres] = useState(0);
  const [cuotaMensual, setCuotaMensual] = useState(0);
  const MESES_EN_ANO = 12;

  const calcularCuotaMensual = (
    capitalPendiente,
    interes,
    plazoAmortizacion
  ) => {
    // Ejemplo: 147000×((((1+0.029÷12)^(30×12))×(0.029÷12))÷((1+0.029÷12)^(30×12)−1))
    const cuota =
      capitalPendiente *
      ((((1 + interes / MESES_EN_ANO) ** (plazoAmortizacion * MESES_EN_ANO)) *
        (interes / MESES_EN_ANO)) /
        ((1 + interes / MESES_EN_ANO) **
          (plazoAmortizacion * MESES_EN_ANO)-1));
    console.log(cuota);
    return cuota;
  };

  return (
    <div>
      <h1>Calculadora de hipoteca</h1>
      <label>Capital pendiente a amortizar o cantidad a hipotecar</label>
      <input
        id="capitalPendiente"
        type="number"
        value={capitalPendiente}
        onChange={(e) => setCapitalPendiente(Number(e.target.value))}
      />
      <label>Plazo en años</label>
      <input
        id="plazoAmortizacion"
        type="number"
        value={plazoAmortizacion}
        onChange={(e) => setPlazoAmortizacion(Number(e.target.value))}
      />
      <label>Interés anual</label>
      <input
        id="interes"
        type="number"
        value={interes}
        onChange={(e) => setInteres(Number(e.target.value))}
      />
      <button
        onClick={() => {
          const cuota = calcularCuotaMensual(
            capitalPendiente,
            interes,
            plazoAmortizacion
          );
          setCuotaMensual(cuota);
        }}
      >
        Calcular
      </button>
      <p>Cuota mensual: {cuotaMensual}</p>
    </div>
  );
};

export default CalculadoraHipoteca;
