import {
  action,
  computed,
  makeAutoObservable,
  observable,
  runInAction
} from 'mobx';
import { v4 as uuidv4 } from 'uuid';

export class DailyExpensesStore {
  transportLayer;
  isLoading;
  isSaving;
  categories: Category[];
  dailyExpenses: DailyExpense[];
  month;
  year;

  constructor(transportLayer) {
    makeAutoObservable(this, {
      // Choose from observable, computed, action
      // Setters should be actions
      // Getters should be computed
      // Own properties should be observable
      categories: observable,
      dailyExpenses: observable,
      isLoading: observable,
      isSaving: observable,
      month: observable,
      year: observable,
      filterDailyExpensesMyMonthAndYear: action,
      setMonth: action,
      setYear: action,
      availableYears: computed
    });
    this.isLoading = false;
    this.isSaving = false;
    this.categories = [];
    this.dailyExpenses = [];
    this.month = new Date().getMonth();
    this.year = new Date().getFullYear();
    this.transportLayer = transportLayer;
    // this.loadDailyExpenses();
    // this.loadCategories();
  }

  filterDailyExpensesMyMonthAndYear(month = this.month, year = this.year) {
    return this.dailyExpenses.filter((gasto) => {
      const date = new Date(gasto.date);
      return date.getMonth() === month && date.getFullYear() === year;
    });
  }

  setMonth(month) {
    this.month = month;
  }

  setYear(year) {
    this.year = year;
  }

  get availableYears() {
    return Array.from(
      new Set(
        this.dailyExpenses.map((gasto) => new Date(gasto.date).getFullYear())
      )
    );
  }

  async loadDailyExpenses() {
    // Make sure categories are loaded before daily expenses
    await this.loadCategories();
    this.isLoading = true;
    const response = await this.transportLayer.getDailyExpenses();
    runInAction(() => {
      response.forEach((expenseJson) =>
        this.updateDailyExpenseFromServer(expenseJson)
      );
      this.isLoading = false;
    });
  }

  updateDailyExpenseFromServer(json) {
    const dailyExpense = this.dailyExpenses.find(
      (gasto) => gasto.id === json.id
    );
    if (dailyExpense) {
      dailyExpense.updateFromJson(json);
    } else {
      this.dailyExpenses.push(new DailyExpense(this, json.id));
    }
  }

  async loadCategories() {
    this.isLoading = true;
    const response = await this.transportLayer.getCategories();
    runInAction(() => {
      response.forEach((categoryJson) =>
        this.updateCategoryFromServer(categoryJson)
      );
      this.isLoading = false;
    });
  }

  updateCategoryFromServer(json) {
    const category = this.categories.find(
      (category) => category.id === json.id
    );
    if (category) {
      category.updateFromJson(json);
    } else {
      this.categories.push(new Category(this, json.id));
    }
  }

  removeCategory(category) {
    this.categories = this.categories.filter((cat) => cat.id !== category.id);
  }

  removeDailyExpense(dailyExpense) {
    this.dailyExpenses = this.dailyExpenses.filter(
      (gasto) => gasto.id !== dailyExpense.id
    );
  }
  // Add a new daily expense to the store
  createDailyExpense(date = new Date(), concept = 'Nombre', cost = 0) {
    const dailyExpense = new DailyExpense(this, uuidv4());
    dailyExpense.date = date;
    dailyExpense.concept = concept;
    dailyExpense.cost = cost;
    this.dailyExpenses.push(dailyExpense);
    return dailyExpense;
  }

  // Add a new category to the store
  createCategory() {
    const category = new Category(this);
    this.categories.push(category);
    return category;
  }

  updateDailyExpense(json) {
    const dailyExpense = this.dailyExpenses.find(
      (expense) => expense.id === json.id
    );
    if (dailyExpense) {
      dailyExpense.updateFromJson(json);
    } else {
      this.dailyExpenses.push(new DailyExpense(this, json.id));
    }
  }

  updateCategory(json) {
    const category = this.categories.find(
      (category) => category.id === json.id
    );
    if (category) {
      category.updateFromJson(json);
    } else {
      this.categories.push(new Category(this, json.id));
    }
  }

  async updateDailyExpenses() {
    await this.transportLayer.saveDailyExpenses(
      this.dailyExpenses.map((expense) => expense.toJson)
    );
    // Reload data
    await this.loadDailyExpenses();
  }

  updateCategories() {
    this.transportLayer.saveCategories(
      this.categories.map((cat) => cat.toJson)
    );
  }
}

export class DailyExpense {
  store: DailyExpensesStore;
  id: String;
  userId: number = -1;
  category: Category;
  concept: string = '';
  cost: number = 0;
  date: Date = new Date();
  isNew: boolean = true;

  constructor(store: DailyExpensesStore, id = '') {
    makeAutoObservable(this, {
      store: true,
      updateFromJson: action,
      delete: action,
      toJson: computed
    });
    this.store = store;
    this.id = id;
    this.category = new Category(this.store, store.categories[0]?.id);
  }

  updateFromJson(json) {
    this.id = json.id;
    this.userId = json.userId;
    this.concept = json.concept;
    this.cost = json.cost;
    this.date = json.date;
    this.isNew = false;
    const category = this.store.categories.find(
      (category) => category.id === json.categoryId
    );
    if (category) {
      this.category = category;
    }
  }

  delete() {
    this.store.dailyExpenses = this.store.dailyExpenses.filter(
      (gasto) => gasto.id !== this.id
    );
  }

  get toJson() {
    return {
      id: this.id,
      userId: this.userId,
      categoryId: this.category.id,
      concept: this.concept,
      cost: this.cost,
      date: this.date,
      isNew: this.isNew
    };
  }
}

export class Category {
  store: DailyExpensesStore;
  id: string;
  name: string = '';
  isNew: boolean = true;

  constructor(store: DailyExpensesStore, id = '') {
    this.store = store;
    this.id = id;
  }

  updateFromJson(json) {
    this.id = json.id;
    this.name = json.name;
    this.isNew = false;
  }

  delete() {
    this.store.categories = this.store.categories.filter(
      (category) => category.id !== this.id
    );
  }

  get toJson() {
    return {
      id: this.id,
      name: this.name,
      isNew: this.isNew
    };
  }
}
